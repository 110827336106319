import React, { createContext, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/order_table'
import PurchaseOrderService from '../../network/services/purchaseOrder'
import { serialize } from '../../network/request'
import PurchaseOrderTable from './PurchaseOrderTable'
import { t } from 'i18next'
import PurchaseOrderDashboard from '../../components/PurchaseOrder/PurcahseOrderDashboard'
import Gaps from '../../components/Gaps'

export const poSelectContext = createContext()
export const poDashboardContext = createContext()

const PurchaseOrderPage = () => {
  // use this to preload
  const { state } = useSnapshot(tableStore)
  const [loading, setLoading] = useState(false)
  const [poIds, setPoIds] = useState([])

  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(PurchaseOrderService.getAll, { page: state.currentPage, limit: state.pageSize })
  )

  return (
    <poSelectContext.Provider value={{ poIds, setPoIds }}>
      <poDashboardContext.Provider>
        <PageHeader title={t('Purchase Orders')}>
          <PurchaseOrderDashboard />
          <Gaps />
          <Card>
            <PurchaseOrderTableWrapper response={response} error={error} />
          </Card>
        </PageHeader>
      </poDashboardContext.Provider>
    </poSelectContext.Provider>
  )
}

const PurchaseOrderTableWrapper = ({ response, error }) => {
  const { state } = useSnapshot(tableStore)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = PurchaseOrderService.toPaginate(response)

  return (
    <>
      <PurchaseOrderTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <PurchaseOrderTable total={total} overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default PurchaseOrderPage
