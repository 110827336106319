import client from '../request'
// useSWR, return strings
const getAll = '/purchase-orders'

// useSWR, return strings
const get = (id) => {
  return `/purchase-orders/${id}`
}

const getProducts = (id) => {
  return `/purchase-orders/${id}/products`
}

// axios
const update = (id, data) => {
  return client.put(`/purchase-orders/${id}`, data)
}

const createPoWithCsv = (data) => {
  const formData = new FormData()
  formData.append('csv', data.csv)
  formData.append('remark', data.remark)
  formData.append('receiver_email', data.receiver_email)
  formData.append('zip', data.zip)
  formData.append('address', data.address)
  formData.append('company', data.company)
  formData.append('first_name', data.first_name)
  formData.append('last_name', data.last_name)
  formData.append('phone', data.phone)

  return client.post('/purchase-orders/csv/create-po', formData)
}

const updateTrackingWithCsv = (data) => {
  return client.post('/purchase-orders/csv/update-tracking', data)
}

// processing
const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      const dateCreated = new Date(element?.created_at)
      const dateFulfilled = element?.fulfilled_at ? new Date(element?.fulfilled_at) : ''
      return {
        ...element,
        created_at: dateCreated.toLocaleString(),
        key: element?.id
      }
    })
  }

  return []
}

// processing
const productToRow = (data) => {
  if (data?.length > 0) {
    return data.map((element) => {
      return {
        ...element,
        quantity: Math.abs(element?.quantity ?? 0),
        key: element?.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

//downloadCSV
const downloadCsv = (data) => {
  return client.post('/purchase-orders/csv/download', data, {
    responseType: 'blob'
  })
}

const PurchaseOrderService = {
  getAll,
  get,
  getProducts,
  toPaginate,
  update,
  productToRow,
  toRow,
  downloadCsv,
  createPoWithCsv,
  updateTrackingWithCsv
}

export default PurchaseOrderService
