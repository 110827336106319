import { Suspense, useEffect } from 'react'
import { Space, Spin } from 'antd'
import { Routes, BrowserRouter, Route, Navigate, Outlet } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import './App.less'
import authStore, { checkAuthorization, getProfile } from './lib/store/auth'
import Dashboard from './pages/Dashboard'
import SignIn from './pages/auth/SignIn'

import SettingDashboard from './pages/SettingDashboard'

import ProductPage from './pages/products'
import ProductDetail from './pages/products/ProductDetail'
import AuthenticatedLayout from './layout/AuthenticatedLayout'
import UserPage from './pages/users'
import UserDetail from './pages/users/UserDetail'
import WarehousePage from './pages/warehouses'
import WarehouseDetail from './pages/warehouses/WarehouseDetail'
import EventPage from './pages/events'
import EventDetail from './pages/events/EventDetail'
import ReturnStatusPage from './pages/returnStatus'
import ReturnStatusDetail from './pages/returnStatus/ReturnStatusDetail'
import SourcePage from './pages/sources'
import SourceDetail from './pages/sources/SourceDetail'
import StockPage from './pages/stocks'
import StockDetail from './pages/stocks/StockDetail'
import OrderPage from './pages/order'
import OrderDetail from './pages/order/OrderDetail'
import CustomerPage from './pages/customer'
import CustomerDetail from './pages/customer/CustomerDetail'
import BlacklistPage from './pages/blacklist'
import BlacklistDetail from './pages/blacklist/BlacklistDetail'
import ProductImportPage from './pages/productImport/ImportForm'

import YamatoSettingDetail from './pages/yamatoSetting/YamatoSettingDetail'
import FulfillmentSettingDetail from './pages/fulfillmentSetting/FulfillmentSettingDetail'
import ShopifySettingDetail from './pages/shopifySetting/ShopifySettingDetail'

import Barcode from './pages/Barcode'
import ReviewPage from './pages/reviews'
import ReviewDetail from './pages/reviews/ReviewDetail'

import Scanner from './pages/scanner'
// import OrderScanning from './pages/scanner/OrderScanning'
import Handheld from './pages/scanner/Handheld'

import ContactPage from './pages/contact'
import ContactDetail from './pages/contact/ContactDetail'

import RecruitmentPage from './pages/recruitment'
import RecruitmentDetail from './pages/recruitment/RecruitmentDetail'
import FeedbackPage from './pages/feedbacks'
import { ThemeProvider } from 'theme-ui'
import { theme } from './theme'
import { useTranslation } from 'react-i18next'
import languageStore from './lib/store/language'
import StockScanner from './pages/stockScanner'
import StockHandheld from './pages/stockScanner/Handheld'
import ReportsPage from './pages/reports'
import PurchaseOrderPage from './pages/purchaseOrder'
import PurchaseOrderDetail from './pages/purchaseOrder/PurchaseOrderDetail'
import PoScanner from './pages/poScanner'

function PrivateRoute({ children, redirectTo }) {
  const {
    state: { token: isLoggedIn }
  } = useSnapshot(authStore)

  return isLoggedIn ? children : <Navigate to={redirectTo} />
}

function PermissionAllowedRoute({ children, permission }) {
  const admin = getProfile().admin
  const access = admin[permission]
  console.log(access)

  return access ? <Outlet /> : <Navigate to="/dashboard" />
}

//TODO: add a parent compoenent to the routes to check admin access
function App() {
  const { i18n } = useTranslation()
  const {
    state: { language }
  } = useSnapshot(languageStore)

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language)
    }
  }, [language, i18n])

  return (
    <Suspense
      fallback={
        <Space align="center" style={{ width: '100vw', height: '100vh', justifyContent: 'center' }}>
          <Spin />
        </Space>
      }
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/scanner"
              element={
                <PrivateRoute redirectTo="/">
                  <Outlet />
                </PrivateRoute>
              }
            >
              <Route path="" element={<Scanner />} />
              <Route path="handheld" element={<Handheld />} />
            </Route>
            <Route
              path="/stock-scanner"
              element={
                <PrivateRoute redirectTo="/">
                  <Outlet />
                </PrivateRoute>
              }
            >
              <Route path="" element={<StockScanner />} />
              <Route path="handheld" element={<StockHandheld />} />
            </Route>

            <Route exact path="po-scanner" element={<PoScanner />} />

            <Route
              path="/dashboard"
              element={
                <PrivateRoute redirectTo="/">
                  <AuthenticatedLayout />
                </PrivateRoute>
              }
            >
              <Route exact path="" element={<Dashboard />} />
              <Route exact path="barcode" element={<Barcode />} />

              <Route exact path="reports" element={<ReportsPage />} />
              <Route exact path="setting_dashboard" element={<SettingDashboard />} />
              <Route exact path="yamato_setting/:id" element={<YamatoSettingDetail />} />
              <Route exact path="fulfillment_setting/:id" element={<FulfillmentSettingDetail />} />
              <Route exact path="shopify_setting/:id" element={<ShopifySettingDetail />} />

              <Route element={<PermissionAllowedRoute permission="products_access" />}>
                <Route exact path="products" element={<ProductPage />} />
                <Route exact path="products/:id" element={<ProductDetail />} />
                <Route exact path="products/import/:type" element={<ProductImportPage />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="reviews_access" />}>
                <Route exact path="reviews" element={<ReviewPage />} />
                <Route exact path="reviews/:id" element={<ReviewDetail />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="users_access" />}>
                <Route exact path="users" element={<UserPage />} />
                <Route exact path="users/:id" element={<UserDetail />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="events_access" />}>
                <Route exact path="events" element={<EventPage />} />
                <Route exact path="events/:id" element={<EventDetail />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="stocks_access" />}>
                <Route exact path="stocks" element={<StockPage />} />
                <Route exact path="stocks/:id" element={<StockDetail />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="warehouses_access" />}>
                <Route exact path="warehouses" element={<WarehousePage />} />
                <Route exact path="warehouses/:id" element={<WarehouseDetail />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="returns_access" />}>
                <Route exact path="return_status" element={<ReturnStatusPage />} />
                <Route exact path="return_status/:id" element={<ReturnStatusDetail />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="sources_access" />}>
                <Route exact path="sources" element={<SourcePage />} />
                <Route exact path="sources/:id" element={<SourceDetail />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="orders_access" />}>
                <Route exact path="order" element={<OrderPage />} />
                <Route exact path="order/:id" element={<OrderDetail />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="orders_access" />}>
                <Route exact path="purchase-orders" element={<PurchaseOrderPage />} />
                <Route exact path="purchase-orders/:id" element={<PurchaseOrderDetail />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="customers_access" />}>
                <Route exact path="customer" element={<CustomerPage />} />
                <Route exact path="customer/:id" element={<CustomerDetail />} />
              </Route>

              <Route element={<PermissionAllowedRoute permission="blacklists_access" />}>
                <Route exact path="blacklist" element={<BlacklistPage />} />
                <Route exact path="blacklist/:id" element={<BlacklistDetail />} />
              </Route>

              <Route exact path="contact" element={<ContactPage />} />
              <Route exact path="contact/:id" element={<ContactDetail />} />
              <Route exact path="recruitment" element={<RecruitmentPage />} />
              <Route exact path="recruitment/:id" element={<RecruitmentDetail />} />
              <Route exact path="feedbacks" element={<FeedbackPage />} />
            </Route>

            <Route path="/sign-in" exact element={<SignIn />} />
            <Route path="/" exact element={<SignIn />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  )
}

new Promise(() => {
  checkAuthorization()
}).catch((error) => console.error(error))

export default App
