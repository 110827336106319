import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader, Button } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import OrderService from '../../network/services/order'
import PurchaseOrderForm from './PurchaseOrderForm'
import PurchaseOrderService from '../../network/services/purchaseOrder'

const PurchaseOrderDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const saveAction = (
    <Button
      key={'save'}
      type="primary"
      onClick={() => {
        form.submit()
      }}
      loading={isLoading}
    >
      Save
    </Button>
  )

  const { data: response, error, mutate } = useSWR(PurchaseOrderService.get(id))

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const initialValues = {
    ...response
  }

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }
  return (
    <PageHeader
      title={'View Purchase Order Detail'}
      onBack={() => navigate('/dashboard/purchase-orders')}
      extra={[saveAction]}
    >
      <PurchaseOrderForm
        form={form}
        initialValue={initialValues}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        refresh={refresh}
        readonly={initialValues.status == 'fulfilled'}
      />
    </PageHeader>
  )
}

export default PurchaseOrderDetail
