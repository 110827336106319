import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Empty, Spin, Button, message } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/order_table'
import OrderService from '../../network/services/order'
import { serialize } from '../../network/request'
import OrderTable from './OrderTable'
import { t } from 'i18next'

const OrderPage = () => {
  // use this to preload
  const { state } = useSnapshot(tableStore)
  const [loading, setLoading] = useState(false)
  const {
    data: response,
    error,
    mutate
  } = useSWR(serialize(OrderService.getAll, { page: state.currentPage, limit: state.pageSize }))

  const handleShopifyImport = async () => {
    message.loading('Importing orders from shopify')
    setLoading(true)
    try {
      const { data: response } = await OrderService.importShopify()
      if (response.success) {
        message.success(`successfully imported ${response.data.length} orders from Shopify`)
      }
    } catch (e) {
      console.log(e)
      message.error(e.message)
    }
    setLoading(false)
    mutate({}, true)
  }

  const primaryAction = (
    <Button key="import-shopify" type="primary" onClick={handleShopifyImport} loading={loading}>
      Import orders from Shopify
    </Button>
  )

  return (
    <PageHeader title={t('Orders')} extra={[primaryAction]}>
      <Card>
        <OrderTableWrapper response={response} error={error} />
      </Card>
    </PageHeader>
  )
}

const OrderTableWrapper = ({ response, error }) => {
  const { state } = useSnapshot(tableStore)
  const { data: transactionStatus } = useSWR(OrderService.getTransactionStatuses)
  const { data: fulfillmentStatus } = useSWR(OrderService.getFulfillmentStatuses)

  const transactionStatusRow = OrderService.statusToRow(transactionStatus)
  const fulfillmentStatusRow = OrderService.statusToRow(fulfillmentStatus)

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = OrderService.toPaginate(response)

  return (
    <>
      <OrderTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <OrderTable
            total={total}
            overridePage={state.currentPage + 1}
            transactionStatuses={transactionStatusRow}
            fulfillmentStatuses={fulfillmentStatusRow}
          />
        </div>
      )}
    </>
  )
}

export default OrderPage
