import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Button, Checkbox, Col, Row, Form, Input, Select, message } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import AppSettingService from '../../network/services/appSetting'
import { saveAs } from 'file-saver'
import Gaps from '../Gaps'
import { useTranslation } from 'react-i18next'
import dashboardOptionsStore from '../../lib/store/po_dashboard_store'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'
import _ from 'lodash'
import { poSelectContext } from '../../pages/purchaseOrder'
import PurchaseOrderService from '../../network/services/purchaseOrder'

const PurchaseOrderDownloadCSV = () => {
  const [form] = Form.useForm()
  const excludeSku = Form.useWatch('exclude_sku', form)
  // const generatePickingList = Form.useWatch('generate_picking_list', form)
  const [enableDefaultName, setEnableDefaultName] = useState(true)
  const [loading, setLoading] = useState(false)
  const { poIds } = useContext(poSelectContext)
  const { state } = useSnapshot(dashboardOptionsStore)
  const { data: appsetting } = useSWR(AppSettingService.get)
  const defaultItemName = useMemo(() => {
    if (appsetting) {
      return appsetting?.fulfillment_setting?.default_item_name ?? ''
    }
    return ''
  }, [appsetting])

  const { t } = useTranslation('translation', { keyPrefix: 'purchaseOrder' })
  const { t: generalT } = useTranslation('translation')

  useEffect(() => {
    dashboardOptionsStore.state.exclude_sku = excludeSku
  }, [excludeSku])

  useEffect(() => {
    var defaultName = ''
    if (enableDefaultName) {
      defaultName = defaultItemName
    }
    form.setFieldsValue({
      default_item_name: defaultName
    })
  }, [enableDefaultName, form, defaultItemName])

  useEffect(() => {
    if (poIds.length > 0) {
      form.setFieldsValue({
        waiting_shipped_only: false
      })
      return
    }
    form.setFieldsValue({
      waiting_shipped_only: true
    })
  }, [poIds, form])

  const handleDownload = async (values) => {
    setLoading(true)
    const data = {
      ...values,
      purchase_order_ids: poIds,
      exclude_sku:
        values?.exclude_sku?.length > 0
          ? values.exclude_sku
              .split(',')
              .map((item) => _.trim(item))
              .filter((item) => !_.isEmpty(item))
          : undefined,
      include_sku:
        values?.include_sku?.length > 0
          ? values.include_sku
              .split(',')
              .map((item) => _.trim(item))
              .filter((item) => !_.isEmpty(item))
          : undefined
    }
    try {
      const { data: response } = await PurchaseOrderService.downloadCsv(data)
      saveAs(response, 'poCSV.zip')
    } catch (e) {
      const text = (await e?.response?.data?.text()) ?? '{}'
      message.error(text)
    }
    setLoading(false)
  }

  // const handleStartDate = (date, dateString) => {
  //   console.log(dateString)
  //   setStartDate(dateString)
  // }

  // const handleEndDate = (date, dateString) => {
  //   setEndDate(dateString)
  // }

  return (
    <Form
      form={form}
      name="download_csv"
      layout="horizontal"
      onFinish={handleDownload}
      initialValues={{
        waiting_shipped_only: true,
        remove_jp_phone_code: true,
        generate_picking_list: true,
        sort_by: 'id',
        desc: false,
        id_column: 'id',
        full_exclude_sku: true,
        tracking_company_id: 1,
        ...state
      }}
    >
      <Row>
        <Col>
          <Form.Item name="remove_jp_phone_code" valuePropName="checked">
            <Checkbox>{t('option2')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Item name="waiting_shipped_only" valuePropName="checked">
            <Checkbox>{t('option3')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Item name="generate_picking_list" valuePropName="checked">
            <Checkbox>{t('option4')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item name="sort_by" label={generalT('Sort by')}>
            <Select>
              <Select.Option value="id">ID</Select.Option>
              <Select.Option value="product_name">Product Name</Select.Option>
              <Select.Option value="sku">SKU</Select.Option>
              <Select.Option value="created_at">Created At</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="desc" label={generalT('Direction')}>
            <Select>
              <Select.Option value={false}>{generalT('Ascending')}</Select.Option>
              <Select.Option value={true}>{generalT('Descending')}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <Form.Item name="tracking_company_id" label={generalT('Courier')}>
            <Select>
              <Select.Option value={1}>{generalT('Yamato')}</Select.Option>
              <Select.Option value={2}>{generalT('Sagawa')}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item name="id_column" label="お客様管理番号">
            <Select>
              <Select.Option value="id">Order ID</Select.Option>
              <Select.Option value="name">Order Name</Select.Option>
              <Select.Option value="platform_order_id">Platform Order ID</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item name="exclude_sku" label="CSVから除外したいSKUを指定する">
            <Input placeholder="sku_A,sku_B,sku_C" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="full_exclude_sku" valuePropName="checked">
            <Checkbox>指定されたSKUを含む注文を全部CSVから除外する</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item name="include_sku" label="出力したいSKUを指定する">
            <Input placeholder="sku_A,sku_B,sku_C" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="full_include_sku" valuePropName="checked">
            <Checkbox>指定されたSKUを含む注文を出力する</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col>
          <Checkbox
            checked={enableDefaultName}
            onClick={() => setEnableDefaultName(!enableDefaultName)}
          >
            {t('option5')}
          </Checkbox>
        </Col>
      </Row>

      {enableDefaultName && (
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item name="default_item_name" label={t('defaultItemName')}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Gaps />
      <Row gutter={[12, 12]}>
        <Col span={24} order="center">
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            size={'large'}
            onClick={() => form.submit()}
            loading={loading}
          >
            {generalT('Download')}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default PurchaseOrderDownloadCSV
