import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import dashboard from '../src/translations/dashboard'
import jaGeneral from '../src/translations/jaGeneral.json'
import orderScanning from '../src/translations/orderScanning.json'
import sidebar from '../src/translations/sidebar.json'
import review from '../src/translations/review.json'
import purchaseOrder from '../src/translations/purchaseOrder.json'

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const resources = {
  en: {
    translation: {
      dashboard: dashboard.en,
      purchaseOrder: purchaseOrder.en
    }
  },
  ja: {
    translation: {
      ...jaGeneral,
      ...sidebar,
      review: review,
      dashboard: dashboard.ja,
      orderScanning: orderScanning,
      purchaseOrder: purchaseOrder.ja
    }
  }
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: false,
    debug: true,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
