import { CopyOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import React from 'react'
import Gaps from './Gaps'

const CopyableItem = ({ title, content, enableCopy = true }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(content)
    message.success('successfully copied to your clipboard')
  }
  return (
    <>
      <div>
        <div>{title}</div>
        <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', minHeight: 30 }}>
          <span>{content ?? '-'}</span>
          {enableCopy && content && (
            <Button type="link" onClick={handleCopy}>
              <CopyOutlined />
            </Button>
          )}
        </div>
      </div>
      <Gaps />
    </>
  )
}

export default CopyableItem
