import React, { useMemo } from 'react'
import {
  AuditOutlined,
  BarsOutlined,
  BookOutlined,
  BranchesOutlined,
  CheckSquareOutlined,
  DashboardOutlined,
  HomeOutlined,
  ImportOutlined,
  PieChartOutlined,
  RollbackOutlined,
  // MailOutlined,
  // PhoneOutlined,
  // ReadOutlined,
  ScanOutlined,
  SettingOutlined,
  StarOutlined,
  TagOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons'

import authStore from './store/auth'
import { useSnapshot } from 'valtio'
import { useTranslation } from 'react-i18next'

// super admin 0
// admin 1
// operator 2

const useSidebarOption = () => {
  const { state } = useSnapshot(authStore)
  const { t } = useTranslation('translation')
  const admin = state?.profile?.admin ?? null
  const options = useMemo(() => {
    if (!admin) return []
    const options = [
      admin.products_access && {
        key: '/dashboard',
        label: t('Dashboard'),
        icon: <DashboardOutlined />,
        level: 2
      },
      admin.products_access && {
        key: '/dashboard/reports',
        label: t('Report Generation'),
        icon: <PieChartOutlined />,
        level: 1
      },
      admin.products_access && {
        key: '/dashboard/products',
        label: t('Products'),
        icon: <TagOutlined />,
        level: 2
      },
      admin.orders_access && {
        key: '/dashboard/order',
        label: t('Orders'),
        icon: <BarsOutlined />,
        level: 2
      },
      admin.orders_access && {
        key: '/dashboard/purchase-orders',
        label: t('Purchase Orders'),
        icon: <CheckSquareOutlined />,
        level: 2
      },
      admin.reviews_access && {
        key: '/dashboard/reviews',
        label: t('Reviews'),
        icon: <StarOutlined />,
        level: 2
      },
      // {
      //   key: '/dashboard/feedbacks',
      //   label: 'Feedbacks',
      //   icon: <ReadOutlined />,
      //   level: 2
      // },
      admin.users_access && {
        key: '/dashboard/users',
        label: t('Users'),
        icon: <UserOutlined />,
        level: 2
      },
      admin.stocks_access && {
        key: '/dashboard/stocks',
        label: t('Stocks'),
        icon: <BookOutlined />,
        level: 2
      },
      admin.events_access && {
        key: '/dashboard/events',
        label: t('Events'),
        icon: <AuditOutlined />,
        level: 2
      },
      admin.warehouses_access && {
        key: '/dashboard/warehouses',
        label: t('Warehouses'),
        icon: <HomeOutlined />,
        level: 1
      },
      admin.returns_access && {
        key: '/dashboard/return_status',
        label: t('Return Statuses'),
        icon: <RollbackOutlined />,
        level: 1
      },
      admin.sources_access && {
        key: '/dashboard/sources',
        label: t('Sources'),
        icon: <BranchesOutlined />,
        level: 1
      },
      admin.customers_access && {
        key: '/dashboard/customer',
        label: t('Customers'),
        icon: <TeamOutlined />,
        level: 2
      },
      // admin.orders_access && {
      //   key: '/dashboard/order_dashboard',
      //   label: 'Order Dashboard',
      //   icon: <BookOutlined />,
      //   level: 2
      // },
      admin.orders_access && {
        key: '/scanner/',
        label: t('Scan Orders'),
        icon: <ScanOutlined />,
        level: 2
      },
      admin.orders_access && {
        key: '/po-scanner/',
        label: t('Scan Purchase Order'),
        icon: <ScanOutlined />,
        level: 2
      },
      admin.stocks_access && {
        key: '/stock-scanner/',
        label: t('Scan Stocks'),
        icon: <ImportOutlined />,
        level: 2
      },
      admin.blacklists_access && {
        key: '/dashboard/blacklist',
        label: t('Blacklists'),
        icon: <BookOutlined />,
        level: 2
      },
      {
        key: '/dashboard/setting_dashboard',
        label: t('Setting Dashboard'),
        icon: <SettingOutlined />,
        level: 2
      }
      // {
      //   key: '/dashboard/contact',
      //   label: 'Contacts',
      //   icon: <PhoneOutlined />,
      //   level: 2
      // },
      // {
      //   key: '/dashboard/recruitment',
      //   label: 'Recruitments',
      //   icon: <MailOutlined />,
      //   level: 2
      // }
      // {
      //   key: '/dashboard/settings',
      //   label: 'Settings',
      //   icon: <BookOutlined />
      // }
    ]
    return options
  }, [admin, t])

  return options
}

export default useSidebarOption
