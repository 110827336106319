import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Button, Col, Row, Form, Input, Space, message } from 'antd'
import { InboxOutlined, UploadOutlined } from '@ant-design/icons'
import AppSettingService from '../../network/services/appSetting'
import Gaps from '../Gaps'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import _ from 'lodash'
import { poSelectContext } from '../../pages/purchaseOrder'
import TextArea from 'antd/lib/input/TextArea'
import Dragger from 'antd/lib/upload/Dragger'
import PurchaseOrderService from '../../network/services/purchaseOrder'

const PurchaseOrderUploadPoCSV = () => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState()

  const uploadCsvProps = {
    accept: '.csv,.xls',
    fileList,
    multiple: false,
    // action: `${process.env.REACT_APP_BASE_URL}/orders/yamato-csv`,
    action: async (file) => {
      setFileList([file])
    }
  }

  const [enableDefaultName, setEnableDefaultName] = useState(true)
  const [loading, setLoading] = useState(false)
  const { poIds } = useContext(poSelectContext)
  const { data: appsetting } = useSWR(AppSettingService.get)
  const defaultItemName = useMemo(() => {
    if (appsetting) {
      return appsetting?.fulfillment_setting?.default_item_name ?? ''
    }
    return ''
  }, [appsetting])

  const { t } = useTranslation('translation', { keyPrefix: 'purchaseOrder' })
  const { t: generalT } = useTranslation('translation')

  useEffect(() => {
    var defaultName = ''
    if (enableDefaultName) {
      defaultName = defaultItemName
    }
    form.setFieldsValue({
      default_item_name: defaultName
    })
  }, [enableDefaultName, form, defaultItemName])

  const handleDownload = async (values) => {
    console.log(values)
    if (!setFileList || setFileList.length == 0) {
      message.error('No CSV uploaded')
      return
    }

    setLoading(true)
    try {
      const { data: result } = await PurchaseOrderService.createPoWithCsv({
        ...values,
        csv: fileList[0]
      })
      if (result) {
        message.success('Successfully created PO')
        form.resetFields()
        setFileList([])
      }
    } catch (e) {
      const text = (await e.response?.data?.text()) ?? 'Could not create po'
      message.error(text)
    }
    setLoading(false)
  }

  // const handleStartDate = (date, dateString) => {
  //   console.log(dateString)
  //   setStartDate(dateString)
  // }

  // const handleEndDate = (date, dateString) => {
  //   setEndDate(dateString)
  // }

  return (
    <Form
      form={form}
      name="download_csv"
      layout="horizontal"
      onFinish={handleDownload}
      initialValues={{}}
    >
      <Row gutter={[8, 1, 7, 8]}>
        <Col>
          <Row>
            <Col>
              <Form.Item name="company" label={t('receiverCompany')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="address"
                label={t('receiverAddress')}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item name="apartment" label={t('receiverApartment')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="zip"
                label={t('receiverZipCode')}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col>
          <Gaps />
        </Col>

        <Col>
          <Row>
            <Col>
              <Form.Item
                name="first_name"
                label={`${t('receiverName')} (${generalT('lastName')})`}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Gaps />
            <Col>
              <Form.Item
                name="last_name"
                label={`${t('receiverName')} (${generalT('firstName')})`}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="receiver_email"
                label={t('receiverEmail')}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                name="phone"
                label={t('receiverPhone')}
                required
                rules={[{ required: true, message: 'Please enter a value' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item name="remark" label={t('remark')}>
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Dragger {...uploadCsvProps}>
            <Space direction="vertical" style={{ padding: 8 }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('uploadTitle')}</p>
              <p className="ant-upload-hint">{t('uploadDescription')}</p>
            </Space>
          </Dragger>
        </Col>

        <Col>
          <Gaps />
        </Col>

        <Col>
          <Button
            type="primary"
            shape="round"
            icon={<UploadOutlined />}
            size={'large'}
            onClick={() => form.submit()}
            loading={loading}
          >
            {generalT('upload')}
          </Button>
        </Col>
      </Row>

      <Gaps />
    </Form>
  )
}

export default PurchaseOrderUploadPoCSV
