import React, { createContext, useState } from 'react'
import { Card, PageHeader, Tabs } from 'antd'
import Gaps from '../components/Gaps'
import { useNavigate } from 'react-router-dom'
import { YamatoDownloadCSV, YamatoUploadCSV } from '../components/YamatoDashboard'
import DashboardOrderTable from '../components/DashboardOrderTable'
import { useTranslation } from 'react-i18next'
import YamatoTab from '../components/YamatoTab'
import SagawaTab from '../components/SagawaTab'
import SagawaPostTab from '../components/SagawaPostTab'
import PurchaseOrderTab from '../components/PurchaseOrderTab'

export const orderSelectContext = createContext()

const Dashboard = () => {
  const [tab, setTab] = useState('1')
  const [orderIds, setOrderIds] = useState([])
  const { t: generalT } = useTranslation('translation')
  //requried to pass message as a prop as it doesnt work in deeper component

  return (
    <orderSelectContext.Provider value={{ orderIds, setOrderIds }}>
      <PageHeader title="Dashboard">
        <Tabs
          defaultActiveKey="1"
          activeKey={tab}
          onChange={(key) => setTab(key)}
          type="card"
          tabBarStyle={{ marginBottom: 0 }}
        >
          <Tabs.TabPane key="1" tab={generalT('Yamato')}>
            <YamatoTab />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab={generalT('Sagawa')}>
            <SagawaTab />
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab={generalT('Sagawa Post')}>
            <SagawaPostTab />
          </Tabs.TabPane>
          <Tabs.TabPane key="4" tab={generalT('Nippon Express')}>
            <Card bordered={false}></Card>
          </Tabs.TabPane>
        </Tabs>
        <Gaps />
        <DashboardOrderTable />
      </PageHeader>
    </orderSelectContext.Provider>
  )
}

export default Dashboard
