import client from '../request'

export const TrackingCompanies = ['ヤマト運輸', '佐川急便', '佐川ポスト投函']

// useSWR, return strings
const getAll = '/po-fulfillments'

// useSWR, return strings
const get = (id) => {
  return `/po-fulfillments/${id}`
}

const fulfill = (id, data) => {
  return client.post(`/po-fulfillments/${id}/fulfill`, data)
}

// processing
const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

// processing
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const PurchaseOrderFulfillmentService = {
  getAll,
  get,
  toRow,
  toPaginate,
  fulfill
}

export default PurchaseOrderFulfillmentService
