import React, { useState } from 'react'
import { message, Row, Col, Space, Select, Form } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import Dragger from 'antd/lib/upload/Dragger'
import { getToken } from '../../lib/store/auth'
import { useSnapshot } from 'valtio'
import dashboardOptionsStore from '../../lib/store/dashboard_options'
import { useTranslation } from 'react-i18next'
import PurchaseOrderService from '../../network/services/purchaseOrder'

const PurchaseOrderUploadLabel = ({ setOrders }) => {
  const [fileList, setFileList] = useState()
  const token = getToken()
  const [form] = Form.useForm()
  const idColumn = Form.useWatch('id_column', form)
  const trackingCompanyId = Form.useWatch('tracking_company_id', form)
  const { t: generalT } = useTranslation('translation')
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' })

  const props = {
    // name: 'yamato_csv',
    accept: '.csv,.xls',
    fileList,
    multiple: false,
    // action: `${process.env.REACT_APP_BASE_URL}/orders/yamato-csv`,
    action: async (file) => {
      const formData = new FormData()
      formData.append('csv', file)
      formData.append('tracking_company_id', trackingCompanyId)
      formData.append('id_column', idColumn)
      if (window.confirm('Are you sure you want to upload this file?')) {
        try {
          const { data: response } = await PurchaseOrderService.updateTrackingWithCsv(formData)
          if (response.success) {
            message.success(`${file.name} file uploaded successfully`)
            // setOrders([...response.data.orders])
          }
        } catch (e) {
          message.error(`${file.name} file upload failed. ${e.message}`)
        }
      }
      setFileList([])
    },
    headers: {
      contentType: 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  }

  return (
    <>
      <Form
        form={form}
        name="download_csv"
        layout="horizontal"
        initialValues={{
          tracking_company_id: 1,
          id_column: 'id'
        }}
      >
        <Row>
          <Col span={8}>
            <Form.Item name="id_column" label="お客様管理番号">
              <Select>
                <Select.Option value="id">Order ID</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item name="tracking_company_id" label={generalT('Courier')}>
              <Select>
                <Select.Option value={1}>{generalT('Yamato')}</Select.Option>
                <Select.Option value={2}>{generalT('Sagawa')}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col>
          <Dragger {...props}>
            <Space direction="vertical" style={{ padding: 8 }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('uploadTitle')}</p>
              <p className="ant-upload-hint">{t('uploadDescription')}</p>
            </Space>
          </Dragger>
        </Col>
      </Row>
    </>
  )
}

export default PurchaseOrderUploadLabel
