import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  message,
  notification,
  PageHeader,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Typography
} from 'antd'
import CopyableItem from '../../components/CopyableItem'
import { red } from '@ant-design/colors'
import { useNavigate } from 'react-router-dom'
import { ScanOutlined, WarningOutlined } from '@ant-design/icons'
import React, { useState, useMemo, useRef, useEffect, createContext, useContext } from 'react'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import Gaps from '../../components/Gaps'
import useSWR from 'swr'
import { serialize } from '../../network/request'
import _ from 'lodash'
import { TrackingCompanies } from '../../network/services/fulfillment'
import PurchseOrderFulfillmentService from '../../network/services/purchaseOrderFulfillment'
import { useTranslation } from 'react-i18next'
import PurchaseOrderService from '../../network/services/purchaseOrder'
const shiftCharCode = (Δ) => (c) => String.fromCharCode(c.charCodeAt(0) + Δ)
const toHalfWidth = (str) => str.replace(/[！-～]/g, shiftCharCode(-0xfee0))

export const handheldScannerContext = createContext()

const PoScanner = () => {
  //const [barcode, setBarcode] = useState()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const barcodeInputRef = useRef(null)
  const [submitted, setSubmitted] = useState(false)
  const { t } = useTranslation('translation', { keyPrefix: 'orderScanning' })
  // const [delayDebounce, setDelayDebounce] = useState()
  const [tracking, setTracking] = useState('')
  const [trackingCompany, setTrackingCompany] = useState(TrackingCompanies[0])
  const asciiTracking = useMemo(() => (tracking ? toHalfWidth(tracking) : ''), [tracking])

  useEffect(() => {
    barcodeInputRef?.current?.focus()
  })

  const focusTrackingNumber = () => {
    setSubmitted(true)
    barcodeInputRef?.current?.focus()
  }

  const handleSubmit = (data) => {
    if (data.barcode) {
      var barcode = data.barcode?.toLowerCase() ?? ''
      barcode = barcode?.slice(1, data.barcode?.length - 1) ?? ''
      setTracking(barcode)
      setSubmitted(false)
      form.resetFields()
    }
  }

  return (
    <handheldScannerContext.Provider value={{ focusTrackingNumber, submitted }}>
      <PageHeader title=" " onBack={() => navigate('/dashboard/purchase-orders')}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row justify="center">
            <Col span={8}>
              <div style={{ textAlign: 'center' }}>
                <ScanOutlined style={{ fontSize: 100 }} />
                <div>
                  {t('Please scan a barcode of a tracking number with your handheld scanner')}
                </div>
                <Gaps />

                <div style={{ textAlign: 'start' }}>
                  <Select
                    style={{ width: 200 }}
                    value={trackingCompany}
                    onChange={(value) => setTrackingCompany(value)}
                  >
                    {TrackingCompanies.map((item) => {
                      return (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      )
                    })}
                  </Select>

                  <Gaps />

                  <Form form={form} onFinish={handleSubmit} onSubmitCapture={handleSubmit}>
                    <Form.Item name="barcode">
                      <Input
                        ref={barcodeInputRef}
                        placeholder={t('Enter tracking number')}
                        autoComplete="off"
                        style={{ opacity: 1 }}
                      />
                    </Form.Item>
                    <div style={{ textAlign: 'end' }}>
                      <Button type="primary" onClick={() => form.submit()}>
                        Submit
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          <Gaps />
          {asciiTracking && (
            <FulfillmentCard
              tracking={asciiTracking}
              onFulfillmentSuccess={() => {
                focusTrackingNumber()
                setSubmitted(true)
              }}
              trackingCompany={trackingCompany}
            />
          )}
        </Space>
      </PageHeader>
    </handheldScannerContext.Provider>
  )
}

const FulfillmentCard = ({ tracking, onFulfillmentSuccess, trackingCompany }) => {
  //check if any fulfillment matched with the tracking number
  const { submitted } = useContext(handheldScannerContext)
  const { t } = useTranslation('translation', { keyPrefix: 'orderScanning' })
  const { t: poT } = useTranslation('translation', { keyPrefix: 'purchaseOrder' })
  const { t: generalT } = useTranslation('translation')
  const [errorMessage, setErrorMessage] = useState('')
  const {
    data: response,
    error,
    mutate
  } = useSWR(
    serialize(PurchseOrderFulfillmentService.getAll, {
      tracking_number: tracking,
      tracking_company_names: trackingCompany
    })
  )
  const errorAudio = new Audio('/audio/error.mp3')
  const successAudio = new Audio('/audio/success.mp3')
  const confirmAudio = new Audio('/audio/confirm.mp3')
  const [barcodeForm] = Form.useForm()
  const [lineItemCheckList, setLineItemCheckList] = useState([])
  const fulfillment = _.first(response?.data)
  const barcodeInputRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const { data: po } = useSWR(
    fulfillment ? PurchaseOrderService.get(fulfillment.purchase_order_id) : null
  )

  //focus on the barcode input at the beginning
  useEffect(() => {
    barcodeInputRef?.current?.focus()
  })

  //set up checklist
  useEffect(() => {
    const checklist = po?.items.map((item) => {
      return {
        ...item,
        currentQty: po.status == 'fulfilled' ? item.qty : 0
      }
    })
    setLineItemCheckList(checklist ? [...checklist] : [])
  }, [po])

  const disableFulfillment = useMemo(() => {
    if (po?.status != 'pending') {
      return true
    }

    if (lineItemCheckList?.length > 0) {
      let allChecked = true
      for (let item of lineItemCheckList) {
        if (item.qty !== item.currentQty) {
          allChecked = false
          break
        }
      }
      return !allChecked
    }

    return true
  }, [lineItemCheckList, po])

  const fulfillingItems = useMemo(() => {
    const line_items = []
    lineItemCheckList.forEach((item) => {
      if (item.currentQty > 0) {
        line_items.push({
          line_item_id: item.id,
          quantity: item.currentQty
        })
      }
    })
    return line_items
  }, [lineItemCheckList])

  if (!response) {
    return (
      <Card>
        <Spin />
      </Card>
    )
  }

  if (error || !po) {
    return (
      <Card>
        <Empty description={'No such purchase order'} />
      </Card>
    )
  }

  const handleCheckProcuct = (barcode) => {
    if (!barcode) return
    setErrorMessage('')
    const asciiBarcode = toHalfWidth(barcode)
    const index = lineItemCheckList.findIndex(
      (item) => item.product_variant.barcode === asciiBarcode
    )
    if (index != -1) {
      const newList = [...lineItemCheckList]
      if (newList[index].currentQty + 1 <= newList[index].qty) {
        newList[index].currentQty += 1
        setLineItemCheckList([...newList])
        successAudio.play()
        return
      }
      setErrorMessage('Maximun amount reached')
      errorAudio.play()
      return
    }
    errorAudio.play()
    setErrorMessage('Scanned barcode does not match any product in the list')
  }

  const handleBarcodeSubmit = (data) => {
    handleCheckProcuct(data.barcode)
    barcodeForm.resetFields()
    barcodeInputRef?.current?.focus()
  }

  const handleMarkAsScanned = (itemId) => {
    const index = lineItemCheckList.findIndex((item) => item.id === itemId)
    if (index !== -1) {
      const newList = [...lineItemCheckList]
      newList[index].currentQty = newList[index].qty
      setLineItemCheckList([...newList])
    }
  }

  const handleFulfillment = async () => {
    if (disableFulfillment) return
    confirmAudio.play()
    if (po.status != 'pending') return
    notification.open({
      message: `Fulfilling po #${po.id} / ${po.name} ...`,
      icon: <Spin />,
      description: (
        <Space direction="vertical">
          {fulfillingItems.map((item) => {
            const oriItem = po.items.find((ori) => `${ori.id}` === `${item.id}`)
            return oriItem ? (
              <Typography.Text>
                {oriItem.fullname} X {item.quantity}
              </Typography.Text>
            ) : (
              <></>
            )
          })}
        </Space>
      ),
      duration: 0,
      key: fulfillment.id,
      placement: 'topLeft'
    })
    const line_items = fulfillingItems
    setLoading(true)
    // message.loading('fulfilling order')
    try {
      const { data: result } = await PurchseOrderFulfillmentService.fulfill(fulfillment.id, {
        line_items: line_items
      })
      if (result.success) {
        notification.close(fulfillment.id)
        message.success('Fulfillment Successful')
        await mutate()
        onFulfillmentSuccess()
      }
    } catch (error) {
      notification.close(fulfillment.id)
      notification.open({
        icon: (
          <Typography.Text style={{ color: red.primary }}>
            <WarningOutlined />
          </Typography.Text>
        ),
        message: (
          <Typography.Text style={{ color: red.primary }}>{`Fail to fulfill order #${po.id} / ${
            po.name
          } / Name: ${po.first_name ?? ''} ${po.last_name ?? ''}`}</Typography.Text>
        ),
        description: error.message,
        placement: 'topLeft',
        duration: 0
      })
      // message.error('Order fulfillment error ' + error.message)
    }
    setLoading(false)
  }

  const itemCol = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku'
    },
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return `${record.product_variant.fullname}`
      }
    },
    {
      title: 'Quantity',
      key: 'qty',
      render: (record) => (
        <Space style={{ color: record.currentQty >= record.qty ? '#1DA57A' : red.primary }}>
          {record.currentQty} / {record.qty} {t('scanned')}
        </Space>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <Space>
          <Button
            onClick={() => handleMarkAsScanned(record.id)}
            disabled={record.currentQty >= record.qty}
          >
            {t('Mark as scanned')}
          </Button>
        </Space>
      )
    }
  ]

  const statusColors = {
    pending: 'blue',
    cancelled: 'grey',
    fulfilled: 'green',
    default: 'black'
  }

  return (
    <div style={{ padding: 8 }}>
      <CopyableItem
        title={generalT('status')}
        content={<span style={{ color: statusColors[po?.status ?? 'default'] }}>{po?.status}</span>}
        enableCopy={false}
      />
      <Row>
        <Col>
          <CopyableItem
            title={poT('receiverName')}
            content={`${po?.first_name} ${po?.last_name}`}
          />
        </Col>
        <Col>
          <CopyableItem title={poT('receiverCompany')} content={po?.company} />
        </Col>
        <Col>
          <CopyableItem title={poT('receiverPhone')} content={po?.phone} />
        </Col>
        <Col>
          <CopyableItem title={poT('receiverEmail')} content={po?.receiver_email} />
        </Col>
      </Row>
      <CopyableItem
        title={poT('receiverAddress')}
        content={`${po?.zip ?? ''} ${po?.address ?? ''} ${po?.apartment ?? ''}`}
      />
      <Row>
        <Col>
          <CopyableItem
            title={generalT('Tracking Company')}
            content={fulfillment?.tracking_company_name}
          />
        </Col>
        <Col>
          <CopyableItem
            title={generalT('Tracking Number')}
            content={fulfillment?.tracking_number}
          />
        </Col>
      </Row>

      {!submitted && (
        <>
          {po.status == 'pending' && (
            <Form form={barcodeForm} onFinish={handleBarcodeSubmit}>
              <Row justify="center">
                <Col>
                  <Form.Item name="barcode">
                    <Input
                      disabled={loading}
                      autoComplete="off"
                      ref={barcodeInputRef}
                      placeholder="Scan a product barcode"
                      style={{ opacity: 1 }}
                    />
                  </Form.Item>
                </Col>
                <Gaps />
                <Col>
                  <Button onClick={() => barcodeForm.submit()} type="primary" loading={loading}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}

          <Table
            dataSource={lineItemCheckList}
            columns={itemCol}
            pagination={{
              pageSize: 999,
              showPrevNextJumpers: false,
              showSizeChanger: false
            }}
          />

          {po?.status == 'pending' && lineItemCheckList.length > 0 && (
            <>
              <Gaps />
              <Row justify="end">
                <Col>
                  <Button
                    disabled={disableFulfillment}
                    loading={loading}
                    onClick={handleFulfillment}
                  >
                    {t('Confirm shipment')}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  )
}
export default PoScanner
